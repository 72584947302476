import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import CloseIcon from "../Icons/CloseIcon"
import FormButton from "../Button/FormButton"
import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import { FormControlLabel } from "../Section"
import {
  Modal,
  ModalTitle,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalBackdrop,
} from "../Modal"

class QuotePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      source: "",
      page: "",
      form: "",
      full_name: "",
      email: "",
      phone_no: "",
      state: null,
      zipcode: "",
      comment: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
    if (id === "comment") {
      if (!value || value.trim() === "") {
        event.target.setCustomValidity("Please fill out this field.")
      } else {
        event.target.setCustomValidity("")
      }
    }
  }
  handleChangeSelect(state) {
    this.setState({ state })
  }
  handleSubmit(event) {
    let utm_source_val = "website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }
    event.preventDefault()
    const { location } = this.props
    const data = {
      source: location.origin,
      page: location.href,
      form: event.target.id,
      first_name: this.state.full_name,
      email: this.state.email,
      phone_no: this.state.phone_no,
      state: this.state.state.value,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
      utm_medium: utm_medium_val,
      utm_term: utm_term_val,
      utm_campaign: utm_campaign_val,
      utm_content: utm_content_val,
      lead_source: utm_source_val,
      api_token: process.env.GATSBY_FORM_CRM_TOKEN,
    }
    if (typeof window !== `undefined`) {
      document.getElementById("quotePopupBtn").setAttribute("disabled", "")
    }
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        if (typeof dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "form_submission",
            formName: "QuotePopup",
            enhanced_conversion_data: {
              email: this.state.email,
              phone_number: this.state.phone_no,
            },
          })
        }
        document.body.classList.remove("popup-open")
        this.setState({
          source: "",
          page: "",
          form: "",
          full_name: "",
          email: "",
          phone_no: "",
          state: "",
          zipcode: "",
          comment: "",
        })
        navigate("/thank-you")
        if (typeof window !== `undefined`) {
          document.body.classList.remove("modal-open")
        }
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    const { isVisible } = this.props
    return (
      <>
        <Modal
          className={"modal fade " + (isVisible ? "show" : "")}
          style={{ display: isVisible ? "block" : "none" }}
        >
          <ModalDialog className="modal-dialog modal-xl modal-dialog-centered">
            <ModalContent className="modal-content">
              <ModalBody className="modal-body">
                <CloseButton className="close" onClick={this.props.onClose}>
                  <CloseIcon />
                </CloseButton>
                <ModalTitle className="modal-title">
                  Get Started Today With Your Free Quote
                </ModalTitle>
                <form
                  id="quoteform"
                  className="quoteform grid"
                  onSubmit={this.handleSubmit}
                >
                  <div className="grid-12">
                    <div className="form-group-bordered">
                      <input
                        type="text"
                        id="full_name"
                        className="form-control"
                        pattern="^(?!\s*$)[A-Za-z\s]+$"
                        onChange={this.handleChange}
                        required
                      />
                      <FormControlLabel
                        htmlFor="full_name"
                        className={
                          this.state.full_name !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Name
                      </FormControlLabel>
                    </div>
                  </div>
                  <div className="grid-12">
                    <div className="form-group-bordered">
                      <input
                        type="email"
                        id="email"
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                      />
                      <FormControlLabel
                        htmlFor="email"
                        className={
                          this.state.email !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Email Address
                      </FormControlLabel>
                    </div>
                  </div>
                  <div className="grid-12">
                    <div className="form-group-bordered">
                      <input
                        type="tel"
                        id="phone_no"
                        className="form-control"
                        onChange={this.handleChange}
                        pattern="[0-9]{10}"
                        required
                      />
                      <FormControlLabel
                        htmlFor="phone_no"
                        className={
                          this.state.phone_no !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Phone Number
                      </FormControlLabel>
                    </div>
                  </div>
                  <div className="grid-md-6">
                    <div className="form-group-bordered">
                      <input
                        id="zipcode"
                        type="text"
                        className="form-control"
                        onChange={this.handleChange}
                        pattern="^[0-9]{5}$"
                        required
                      />
                      <FormControlLabel
                        htmlFor="zipcode"
                        className={
                          this.state.zipcode !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Zip Code
                      </FormControlLabel>
                    </div>
                  </div>
                  <div className="grid-md-6">
                    <div className="form-group-bordered form-group-select">
                      <select
                        id="state"
                        className="form-control"
                        onChange={this.handleChange}
                        aria-label="state"
                        required
                      >
                        <option value="" className="placeholder">State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                  </div>
                  <div className="grid-12">
                    <div className="form-group-bordered">
                      <textarea
                        id="comment"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                      />
                      <FormControlLabel
                        htmlFor="comment"
                        className={
                          this.state.comment !== ""
                            ? "control-label active"
                            : "control-label"
                        }
                      >
                        Type Your Message Here…
                      </FormControlLabel>
                    </div>
                  </div>
                  <div className="grid-12">
                    <div className="form-action">
                      <FormButton
                        id="quotePopupBtn"
                        text="Submit"
                        icon={<RightLineArrowIcon />}
                      />
                    </div>
                  </div>
                </form>
              </ModalBody>
            </ModalContent>
          </ModalDialog>
        </Modal>
        {isVisible && (
          <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
        )}
      </>
    )
  }
}

export default QuotePopup
